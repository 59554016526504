export const NOTE_LIST_REQUEST = 'NOTE_LIST_REQUEST';
export const NOTE_LIST_SUCCESS = 'NOTE_LIST_SUCCESS';
export const NOTE_LIST_FAIL = 'NOTE_LIST_FAIL';

export const NOTE_CREATE_REQUEST = 'NOTE_CREATE_REQUEST';
export const NOTE_CREATE_SUCCESS = 'NOTE_CREATE_SUCCESS';
export const NOTE_CREATE_FAIL = 'NOTE_CREATE_FAIL';

export const NOTE_UPDATE_REQUEST = 'NOTE_UPDATE_REQUEST';
export const NOTE_UPDATE_SUCCESS = 'NOTE_UPDATE_SUCCESS';
export const NOTE_UPDATE_FAIL = 'NOTE_UPDATE_FAIL';

export const NOTE_DELETE_REQUEST = 'NOTE_DELETE_REQUEST';
export const NOTE_DELETE_SUCCESS = 'NOTE_DELETE_SUCCESS';
export const NOTE_DELETE_FAIL = 'NOTE_DELETE_FAIL';
